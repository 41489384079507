import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import './registerServiceWorker'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {langsGlobal} from "./mixins/langsGlobal"

import i18n from '@/i18n'
import router from '@/router'
import VueQrcodeReader from 'vue-qrcode-reader'
import Vue2TouchEvents from 'vue2-touch-events'
import '@mdi/font/css/materialdesignicons.css'
import VueMask from 'v-mask'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);


Vue.use(Vue2TouchEvents, {
    touchClass: '',
    tapTolerance: 10,
    swipeTolerance: 30,
    longTapTimeInterval: 3000
})

require('@/assets/css/custom.css');
require('@/assets/css/bag.css');

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(VueQrcodeReader)
Vue.use(VueMask);
Vue.mixin(langsGlobal)

router.beforeEach((to, from, next) => {
    next();
})

new Vue({
    i18n,
    router,
    el: '#app',
    render: h => h(App),
    mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
});
