import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        /*
        {
            path: '/',
            children: [
                {
                    path: '',
                    redirect: {name : "Fashion"} ,
                },
                {
                    path: ':p',
                    redirect: '/fashion/:p',
                }
            ]
        },*/
        {
            path: '/need/scan',
            name: "NeedScan",
            component: () => import('@/components/NeedScan.vue'),
        },
        {
            path: "/fashion/",
            name: "Fashion",
            component: () => import('@/components/Fashion/Fashion.vue'),
            children: [
                {
                    path: '',
                    name: 'index',
                    component: () => import('@/components/Fashion/Home.vue')  
                },
                {
                    path: ':p/',
                    name: 'index',
                    component: () => import('@/components/Fashion/Home.vue')  
                },
                {
                    path: ':p/scan',
                    name: 'Scan',
                    component: () => import('@/components/Fashion/Scan.vue')  
                },
                {
                    path: ':p/basket',
                    name: "Basket",
                    component: () => import('@/components/Fashion/Basket.vue')  
                },
                {
                    path: ':p/paymentmethods',
                    name: "PaymentMethods",
                    component: () => import('@/components/Fashion/PaymentMethods.vue')  
                },
                {
                    path: ':p/paymentdone',
                    name: "PaymentDone",
                    props: (route) => ({
                        paymentSuccess: route.query.paymentSuccess
                    }),
                    component: () => import('@/components/Fashion/PaymentDone.vue') 
                },
                {
                    path: ':p/ticket',
                    component: () => import('@/components/Fashion/Ticket.vue') 
                },
                {
                    path: ':p/account',
                    component: () => import('@/components/Fashion/Account.vue') 
                }
            ]
        },
        {
            path: "/fuel/",
            name: "Fuel",
            component: () => import('@/components/Fuel/Fuel.vue'),
            children: [
                {
                    path: ':p',
                    name: 'FuelHome',
                    component: () => import('@/components/Fuel/HomeFuel.vue')
                },
                {
                    path: ':p/waiting',
                    name: 'WaitingPump',
                    component: () => import('@/components/Fuel/WaitingPump.vue')
                },
                {
                    path: ':p/selection',
                    name: 'SelectionAccordion',
                    component: () => import('@/components/Fuel/SelectionAccordion.vue')
                },
                {
                    path: ':p/ticket',
                    name: 'FuelTicket',
                    component: () => import('@/components/Fuel/Ticket.vue')
                },
                {
                    path: ':p/account',
                    name: 'EnrollementForm',
                    component: () => import('@/components/Fuel/EnrollementForm.vue')
                },
                {
                    path: ':p/editaccount',
                    name: 'EditAccount',
                    component: () => import('@/components/Fuel/EditAccount.vue')
                },
                {
                    path: ':p/3dsecure',
                    name: 'FakeAuthPage',
                    component: () => import('@/components/Fuel/FakeAuthPage.vue')
                },
                {
                    path: ':p/scan',
                    name: 'FuelScan',
                    component: () => import('@/components/Fuel/Scan.vue')
                },
                {
                    path: ':p/coupons',
                    name: 'Coupons',
                    component: () => import('@/components/Fuel/Coupons.vue')

                },
                {
                    path: ':p/basket',
                    name: 'Basket',
                    component: () => import('@/components/Fuel/Basket.vue')

                },
                {
                    path: ':p/cars',
                    name: 'Cars',
                    component: () => import('@/components/Fuel/Cars.vue')

                },
                {
                    path: ':p/history',
                    name: 'History',
                    component: () => import('@/components/Fuel/History.vue')

                }
            ]
        },
        {
            path: "/food/",
            name: "Food",
            component: () => import('@/components/Food/Food.vue'),
            children: [
                {
                    path: '',
                    name: 'FoodIndex',
                    component: () => import('@/components/Food/Scan.vue')  
                },
                {
                    path: ':p/',
                    name: 'FoodIndex',
                    component: () => import('@/components/Food/Scan.vue')  
                },
                {
                    path: ':p/scan',
                    name: 'FoodScan',
                    component: () => import('@/components/Food/Scan.vue')  
                },
                {
                    path: ':p/basket',
                    name: "FoodBasket",
                    component: () => import('@/components/Food/Basket.vue')  
                },
                {
                    path: ':p/paymentmethods',
                    name: "FoodPaymentMethods",
                    component: () => import('@/components/Food/PaymentMethods.vue')  
                },
                {
                    path: ':p/paymentdone',
                    name: "FoodPaymentDone",
                    props: (route) => ({
                        paymentSuccess: route.query.paymentSuccess
                    }),
                    component: () => import('@/components/Food/Ticket.vue') 
                },
                {
                    path: ':p/ticket',
                    name: "FoodPaymentTicket",
                    component: () => import('@/components/Food/Ticket.vue') 
                },
                {
                    path: ':p/account',
                    component: () => import('@/components/Food/Account.vue') 
                },
                {
                    path: ':p/checkin',
                    name: "FoodCheckIn",
                    component: () => import('@/components/Food/CheckIn.vue') 
                },
                {
                    path: ':p/schedule',
                    component: () => import('@/components/Food/StoreSchedule.vue') 
                },
                {
                    path: ':p/sips',
                    component: () => import('@/components/Food/PaymentMethods/Sips.vue') 
                }
            ]
        },
        {
            path: "/backoffice/:demoId/:locale/",
            name: "Backoffice",
            component: () => import('@/components/Backoffice/Backoffice.vue'),
            children: [
                {
                    name: "KeywordsList",
                    path: 'keywords',
                    component: () => import('@/components/Backoffice/KeywordsList.vue') 
                },
                {
                    name: "Basket",
                    path: 'basket/keyword/:keyword',
                    component: () => import('@/components/Backoffice/Basket.vue') 
                }
            ]
        }
    ]
})
